



























































































































































































































































































































import { AxiosError } from 'axios';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { ResCurrentUser } from '@/gen';
import cls from '@/common/classification';

import ConfirmAddress from '@/components/OdakyuPoint/OpExchange/ConfirmAddress.vue';
import OpExchangeFooter from '@/components/OdakyuPoint/OpExchange/OpExchangeFooter.vue';
import OpCalculationPanel from '@/components/OdakyuPoint/OpExchange/OpCalculationPanel.vue';

import AddressForm from '@/components/OdakyuPoint/OpExchange/AddressForm.vue';
import ZipCodeForm from '@/components/profile/ZipCodeForm.vue';

import { UserRepository } from '@/repositories/UserRepository';
import { UserEditForm } from '@/models/forms/UserForm';
import { OpExchangeItem } from '@/models/OpExchangeItem';

@Component({
  components: {
    OpCalculationPanel,
    OpExchangeFooter,
    AddressForm,
    ConfirmAddress,
    ZipCodeForm
  }
})
export default class ItemDetail extends Vue {
  @Prop() item!: OpExchangeItem;
  @Prop() skuItemName!: string;
  @Prop() odakyuCustomerNo!: string;
  @Prop() pointBalance!: number;
  @Prop() isUserSignedIn!: boolean;
  @Ref() readonly AddressForm!: AddressForm;

  selectedImageUrl = '';
  isModalOpen = false;
  isAddressFormOpen = false;
  isAddressConfirmOpen = false;
  isExchangeButtonLoading = false;

  form = {} as UserEditForm;
  beforeAddress = {} as ResCurrentUser;
  currentAddress = '';
  resErrMsg = '';

  get userRepository() {
    return new UserRepository();
  }

  @Watch('$auth.loading', { immediate: true })
  async initialize() {
    if (this.$auth.isAuthenticated && !this.$auth.loading) {
      await this.getCurrentUserAddress();
    }
  }

  get topImageUrl(): string {
    return this.selectedImageUrl || this.item.topImageUrl;
  }

  get exchangeButtonLabel(): string {
    return this.isExchangeButtonLoading ? '申し込み中...' : '交換を申し込む';
  }

  get addressString(): string {
    return (
      cls.getName('PREF_CLS', this.form.pref) +
      this.form.city +
      this.form.town +
      '　' +
      this.form.building
    );
  }

  async getCurrentUserAddress() {
    await this.userRepository
      .getCurrentUser()
      .then((res: ResCurrentUser) => {
        this.beforeAddress = res;
        this.form = new UserEditForm(res);
        this.currentAddress = this.addressString;
      })
      .catch(this.$tokenErrHandler);
  }

  async onClickExchangeButton() {
    if (this.item.updateAddressFlg && this.isModified()) {
      const isValidate = await this.AddressForm.validateForm();
      if (!isValidate) {
        return;
      }

      // 住所確認モーダルを表示する
      if (!this.isAddressConfirmOpen) {
        this.resErrMsg = '';
        this.isAddressConfirmOpen = true;
        return;
      }

      this.isExchangeButtonLoading = true;

      // 住所確認モーダル表示中のとき、ONE 属性変更 API を呼び出す
      try {
        this.resErrMsg = '';
        // ONE属性変更APIの呼び出し
        await this.userRepository.updateUser(this.form);
      } catch (err) {
        this.isExchangeButtonLoading = false;
        this.handleSubmitErr(err);
        return;
      }
    } else {
      this.isExchangeButtonLoading = true;
    }

    // ポイント交換APIの呼び出し
    this.$emit('click-exchange');
  }

  // 住所に変更が入っている場合 true を返却する
  isModified() {
    const isModified =
      this.form.zipcode1 !== this.beforeAddress.zipcode1 ||
      this.form.zipcode2 !== this.beforeAddress.zipcode2 ||
      this.form.pref !== this.beforeAddress.pref ||
      this.form.city !== this.beforeAddress.city ||
      this.form.town !== this.beforeAddress.town ||
      this.form.building !== this.beforeAddress.building;
    return isModified;
  }

  handleSubmitErr(err: AxiosError) {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          this.resErrMsg = this.$msg.get('2000012');
          break;
        default:
          this.resErrMsg = this.$msg.get('2000011');
          break;
      }
    }
  }

  scrollToAddressForm() {
    if (!this.isAddressFormOpen) {
      return;
    }

    const dialogBox = document.getElementById('js-myDialog_box_scroll');
    const addressForm = document.getElementById('js-addressForm');

    window.requestAnimationFrame(() => {
      if (dialogBox && addressForm) {
        if (dialogBox.scrollHeight > dialogBox.clientHeight) {
          // #js-addressFormの位置を取得
          const addressFormPosition =
            addressForm.offsetTop - dialogBox.offsetTop - 20;

          // js-myDialog_box_scroll内でスクロールさせる
          dialogBox.scrollTo({
            top: addressFormPosition,
            behavior: 'smooth' // スムーズスクロール
          });
        }
      }
    });
  }
}
